/* Copyright Robert Quinn 2020*/

/*#region Body*/
body{
  margin: 0;
  padding: 0;
  min-width: 320px;
  font: 16px Montserrat, sans-serif;
  color: #191919;
  overflow-x: hidden;
}
.body{
  margin: auto;
  max-width: 1366px;
}
@supports(padding: max(0px)) {
  .body{
    padding-left: max(0px, env(safe-area-inset-left));
    padding-right: max(0px, env(safe-area-inset-right));
  }
}
.bodymask{
  width: 100%;
  max-width: 100vw;
  overflow: hidden;
}

body, .midground{
  background: #e0e0e0;
}

.portfolio-title{
  position: relative;
  margin: 0px;
  top: 23px;
  float: left;
  text-align: center;
  vertical-align: middle;
  letter-spacing: 1px;
  margin-left: 6px;
}
a{
  color: black;
  text-decoration: none;
  text-align: center;
  font-weight: normal;
  vertical-align: middle;
}
p{
  font-family: Helvetica;
  font-size: 18px;
  line-height: 24px;
}
p, h3, h4, h5{
  display: block;
  margin: 20px;
  color: black;
}
h3{
  font-size: 28px;
  margin-top: 15px;
}
h4{
  margin-bottom: 15px;
  font-size: 15px;
  text-transform: uppercase;
  color: rgba(0, 0, 0, 0.5);
}
h5{
  color: white;
  font: 15px Arial, sans-serif;
  display: block;
  position: absolute;
  bottom: 0px;
  border-radius: 15px;
  text-shadow: 0 0 4px black;
}
.paragraph-title{
  font-weight: bold;
  font-size: 20px;
}

.foreground{
  background: #f0f0f0;
}

@media (prefers-color-scheme: dark) {
  body, .midground{
    background: #1a1a1a;
    transition: background 2s ease-in-out;
  }
  .foreground{
    background: rgb(56, 56, 56);
    transition: background 2s ease-in-out;
  }
  .section-title, .paragraph-title, .portfolio-title, a{
    color: rgb(243, 243, 243);
    transition: color 2s ease-in-out;
  }
  p, .section-subtitle{
    color: rgb(190, 190, 190);
    transition: color 2s ease-in-out;
  }
  p{
    font-weight: 100;
  }
}

/*#endregion Body*/

/*#region Backdrop*/

#backdrop{
  pointer-events: none;
  z-index: 5;
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  margin: 0px;
  overflow: hidden;
  opacity: 0;
  backdrop-filter: blur(0px);
  box-shadow: inset 0 0 0 0vw rgba(255,255,255,0);
  transform: translate3d(0,0,0);
  transition: 0.5s;
}
#backdrop.active{
  pointer-events: inherit;
  opacity: 1;
  backdrop-filter: blur(4px);
  box-shadow: inset 0 0 10px 100vw rgba(255,255,255,0.5);
}
.noscroll{
  overflow: hidden;
}

@media (prefers-color-scheme: dark) {
  #backdrop.active{
    box-shadow: inset 0 0 10px 100vw rgba(0, 0, 0, 0.5);
  }
}

/*#endregion Backdrop*/

/*#region HeaderFooter*/

header img{
  float: left;
  height: 60px;
  width: 60px;
  margin: 6px;
  margin-left: 20px;
  border-radius: 100%;
}
header{
  width: 100%;
  height: 72px;
}
footer{
  width: 100%;
  font-size: 10px;
  min-height: 72px;
  margin-top: 40px;
  padding: 1px;
}
footer p{
  font-size: 10px;
  color: #888;
}
/*#endregion HeaderFooter*/

/*#region Shadows*/

.shadow3D{
  transform: scale(1, 1);
  box-shadow: 0px 10px 20px -8px rgba(0, 0, 0, .5);
  transition: 0.5s;
}
.shadow3D:not(.static):hover{
  z-index: 1;
  transform: scale(1.02, 1.02);
}
.shadow3D:not(.static):hover, .shadow2D{
  box-shadow: 0px 5px 30px -2px rgba(0, 0, 0, .25);
}
.shadow3D:not(.static):active{
  transform: scale(1, 1);
  box-shadow: 0px 0px 12px -1px rgba(0, 0, 0, .65);
}

hr{
  border: 0;
  height: 12px;
  padding: 0px;
  box-shadow: inset 0px 12px 12px -12px #101010;
}

/*#endregion Shadows*/

/*#region CardGrid*/
.cover{
  position: relative;
  height: 100%;
  overflow: hidden;
}
.content .cover{
  height: 200px;
}
.thumb{
  position: absolute;
  top: 0px;
  margin: auto;
  transition: 0.5s;
  opacity: 1;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.cover-details-container{
  z-index: 1;
  display: block;
  position: absolute;
  top: 0px;
  width: 100%;
  height: 100%;
}
.content .cover-details-container{
  background: linear-gradient(to bottom right, rgba(0, 0, 0, 0.26), rgba(0, 0, 0, 0), rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.308));
  box-shadow: inset 0 0 100px rgba(0, 0, 0, 0.719);
}
.content h5{
  display: none;
}
.cover-details-container > h3{
  color: white;
  text-shadow: 0 0 4px rgba(0,0,0,0.25);
}
.cover-details-container > h4{
  color: rgba(255, 255, 255, 0.75);
  text-shadow:  0 0 0px rgba(255,255,255,0.75), 0 0 3px rgba(0,0,0,0.25);
}

section{
  height: auto;
  display: block;
  clear: both;
  margin: 0px;
  padding-top: 30px;
  width: 100%;
}

/*#endregion CardGrid*/

/*#region CardContent*/
.app-icon{
  margin-left: 20px;
  height: 72px;
  border-radius: 17.544%;
  padding: 0px;
  background-color: #eeeeee;
  box-shadow: 0px 2px 8px -2px rgba(20, 20, 20, .5);
}
.badge{
  padding: 8px;
  height: 32px;
}
.prepadded{
  padding: 0px;
  height: 48px;
}
.badge.alt{
  line-height: 32px;
  height: 32px;
}

.links{
  max-width: 500px;
  margin: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.card{
  padding-bottom: 122%;
  transition: 0.5s;
}
.rounded16{
  border-radius: 16px;
}
.rounded16-top{
  border-radius: 16px 16px 0px 0px;
}
.roundedmask{
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  mask-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAIAAACQd1PeAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAA5JREFUeNpiYGBgAAgwAAAEAAGbA+oJAAAAAElFTkSuQmCC);
}

.content{
  z-index: 10;
  position: fixed;
  bottom: 50%;
  left: 50%;
  transform: translate3d(-50%, 50%, 0);
  flex-direction: column;
  overflow: hidden;
  max-width: 700px;
  width: 100%;
  
  max-height: 100%;
  display: none;
  transition: 0.5s;
}
.content.active{
  display: flex;
}
.scroll-content{
  position: relative;
  flex: 1;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  padding-bottom: max(0px, env(safe-area-inset-bottom));
}

.image-with-caption{
  width: auto;
  margin: 20px;
  height: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}
.image-with-caption p{
  width: calc(100% - 40px);
  text-align: center;
  display: block;
  font-size: 15px;
}
.screenshot{
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.screenshot img{
  object-fit: contain;
  max-height: 350px;
  max-width: 100%;
  border-radius: 8px;
}

/*#region MediaScreenSizes*/
.cardcontainer{
  position: relative;
  display: inline-block;
  vertical-align: top;
  margin: 20px;
  min-width: 260px;
  max-width: 350px;
  width: calc(100% - 40px);
  transition: 0.5s;
}
@media all and (max-width: 460px){ /* 1 column 0 wide */
  .body{
    /*the useable max width before the next layout*/
    max-width: 390px;
  }
}
@media all and (min-width: 461px) and (max-width: 760px){ /* 1 column 1 wide */
  .body{
    /*the useable max width before the next layout*/
    max-width: 602.5px;
  }
  .cardcontainer{
    /*golden ratio widths*/
    width: 91.1%;
    min-width: 420px;
    max-width: 565.38px;
  }
  .cardcontainer .card{
    /*inverse golden ratio to maintain height*/
    padding-bottom: 75.61%;
  }
}
@media all and (min-width: 761px) and (max-width: 1060px){ /* 2 column 1 wide */
  .body{
    max-width: 992.5px;
  }
  .cardcontainer{
    /* use element/container ratio 260/810*/
    width: calc(34.16%);
  }
  .cardcontainer:nth-of-type(4n+1):not(.small), .cardcontainer:nth-of-type(4n+4):not(.small){
    /*golden ratio widths*/
    min-width: 420px;
    max-width: 565.38px;
    width: calc(55.19%);
  }
  .cardcontainer:nth-of-type(4n+1):not(.small) .card, .cardcontainer:nth-of-type(4n+4):not(.small) .card{
    /*inverse golden ratio to maintain height*/
    padding-bottom: 75.61%;
  }
}
@media all and (min-width: 1061px){ /* 3 column 1 wide */
  .body{
    max-width: 1382.5px;
  }
  .cardcontainer{
    width: calc(24.50%);
  }
  .cardcontainer:nth-of-type(4n+1):not(.small){
    /*golden ratio widths*/
    min-width: 420px;
    max-width: 562.5px;
    width: calc(39.58%);
  }
  .cardcontainer:nth-of-type(4n+1) .card{
    /*inverse golden ratio to maintain height*/
    padding-bottom: 75.61%;
  }
}
/*#endregion MediaScreenSizes*/
/*#endregion CardContent*/

/*#region Buttons*/

.close{
  background: url("images/textures/X.svg");
  background-size: 100%;
  margin: 8px;
  padding: 16px;
  z-index: 12;
  position: absolute;
  right: 0px;
  opacity: 1;
  transition: 0.25s;
}
.close:hover{
  transform: scale(1.1, 1.1);
}
.close:active{
  transform: scale(1, 1);
  opacity: 0.75;
}

.contactlinks{
  width: auto;
  max-width: 400px;
  margin: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.buttonbox{
  position: relative;
  height: auto;
  margin: 20px;
  width: calc(100% - 40px);
  display: inline-block;
}

.button{
  display: block;
  margin: auto;
  width: 80%;
  max-width: 500px;
  padding: 20px;
  border-radius: 6px;
}
/*#endregion Buttons*/

/*#region Banner*/
.banner{
  position: absolute;
}
.banner, .banner-middle .banner-right, .banner-left{
  height: 44px;
  background-size: 100% 100%;
}

.banner * {
  position: relative;
}

.banner{
  z-index: 5;
  bottom: 72px;
  left: -9px;
  width: calc(100% + 18px);
}
.banner-middle{
  background: url("images/textures/banner-front.svg");
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 6;
}
.banner h3, .banner h4{
  top: 2px;
  margin: 0px;
  margin-left: 10px;
  margin-right: 10px;
  font-size: 20px;
  line-height: auto;
  display: block;
  text-align: center;
  white-space: nowrap;
}
.banner h4{
  font-size: 14px;
}
.banner-right, .banner-left{
  position: absolute;
  background: url("images/textures/banner-right.svg");
  width: 30px;
  height: 44px;
  top: 11px;
}
.banner-corner{
  background: url("images/textures/banner-right-corner.svg");
}
.banner-right{
  float: right;
  right: -21px;
  transform: scale(1, 1);
}
.banner-left{
  float: left;
  left: -21px;
  transform: scale(-1, 1);
}

.scroll-content > .banner{
  min-width: 230px;
  width: auto;
  top: 140px;

  left: 35px;
  right: 35px;
  display: inline-block;
  margin-left: auto;
  margin-right: auto;
}
/*#endregion Banner*/